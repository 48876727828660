<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-header border-0 pt-6">
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          ExcelFilename="Stillnetwork_Offres_commerciales"
          :ExcelFormat="ExcelFormat"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList.length == 0"
          class="
            alert
            bg-light-primary
            alert-primary
            d-flex
            align-items-center
            p-5
            mb-10
          "
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">Aucune facture</h4>
            <span
              >Il n'existe aucune facture à afficher correspondant à vos
              critères de recherche.</span
            >
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          empty-table-text="Aucune facture à afficher."
          :key="state.loadingDatatable"
        >
          <template v-slot:cell-tfa_code="{ row: data }">
            <span class="text-primary">{{ data.tfa_code }}</span>
          </template>
          <template v-slot:cell-tfa_date_creation="{ row: data }">
            {{ formatDate(data.tfa_date_creation) }}
          </template>
          <template v-slot:cell-tfa_date_echeance="{ row: data }">
            {{ formatDate(data.tfa_date_echeance) }}
          </template>
          <template v-slot:cell-pro_nom_projet="{ row: data }">
            <div style="margin-left: 15px" class="text-gray-800">
              <strong>{{ data.pro_nom_projet }}</strong>
              <br />
              <div style="margin-left: 15px" class="text-gray-400">
                <span class="w-150px fs-8">N° Commande Client</span>
                <span style="margin-left: 15px" class="text-gray-600">{{
                  data.toc_num_commande_client
                }}</span>
              </div>
              <div style="margin-left: 15px" class="text-gray-400">
                <span class="w-150px fs-8">N° Offre Stillnetwork</span>
                <span style="margin-left: 23px" class="text-gray-600">
                  <b>{{ data.tof_code }}</b>
                </span>
              </div>
              <div style="margin-left: 15px" class="text-info">
                <span class="fs-8">
                  <b>{{ data.con_prenom_nom }}</b>
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell-tfa_custom_status="{ row: data }">
            <span
              class="me-2 mb-2 btn btn-bg-light btn-sm btn-text-dark"
              :class="styleBtnStatus(data.tfa_custom_status)"
              style="cursor: default"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg :src="styleImgStatus(data.tfa_custom_status)" />
              </span>
              {{ data.tfa_custom_status }}
            </span>
          </template>
          <template v-slot:cell-tfa_total_ht="{ row: data }">
            <div>
              <strong
                >{{ formatMoney(data.tfa_total_ht) }}
                {{ data.dev_sigle }}</strong
              >
            </div>
          </template>
          <template v-slot:cell-tfa_solde="{ row: data }">
            <div>
              <strong v-if="data.tfa_solde > 0" class="text-danger"
                >{{ formatMoney(data.tfa_solde) }} {{ data.dev_sigle }}</strong
              >
            </div>
          </template>
          <template v-slot:cell-toc_num_commande_client="{ row: data }">
            <div>
              <strong>{{ data.toc_num_commande_client }}</strong>
            </div>
          </template>
          <template v-slot:cell-tfa_seq="{ row: data }">
            <a
              class="
                btn btn-sm btn-active-primary
                me-2
                mb-2
                btn-bg-white btn-text-dark
              "
              @click="facturePDF(data)"
            >
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <i class="far fa-file-pdf fs-2 text-primary"></i></span
              >Facture PDF</a
            >
          </template>
        </Datatable>
      </div>
    </div>
  </transition>
  <el-drawer
    v-model="state.drawer"
    title=""
    :with-header="false"
    size="90%"
    modal-class="modal-offre"
  >
    <div class="card shadow-none" style="width: 100%; height: 100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark" v-if="state.mFact.tfa_code">
          Facture N° {{ state.mFact.tfa_code }} du
          {{ formatDate(state.mFact.tfa_date_creation) }}
        </h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            @click="state.drawer = false"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body">
        <iframe
          :src="state.sourcePDF"
          frameborder="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          width="100%"
          height="100%"
          style="
            border: 1px solid #ccc;
            border-width: 1px;
            margin-bottom: 5px;
            max-width: 100%;
            widhth: 100%;
            height: 100%;
          "
          allowfullscreen
        >
        </iframe>
      </div>
      <!--end::Body-->
      <!--end::Footer-->
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des factures",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
    });
    const tableHeader = ref([
      {
        name: "Projet",
        key: "pro_nom_projet",
        sortable: true,
      },
      {
        name: "Date",
        key: "tfa_date_creation",
        sortable: true,
      },
      {
        name: "Echéance",
        key: "tfa_date_echeance",
        sortable: true,
      },
      {
        name: "Numéro de facture",
        key: "tfa_code",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tfa_custom_status",
        sortable: true,
      },
      {
        name: "Total HT",
        key: "tfa_total_ht",
        sortable: true,
      },
      {
        name: "Solde",
        key: "tfa_solde",
        sortable: true,
      },
      {
        name: "Afficher",
        key: "tfa_seq",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Demandeurs",
        key: "con_seq",
        label: "con_prenom_nom",
      },
      {
        name: "Status",
        key: "tfa_custom_status",
        label: "tfa_custom_status",
        default : ["A régler","Facture échue"]
      },
    ]);

    const ExcelFormat = ref({
      Projet: "pro_nom_projet",
      "Numero commande client": "toc_num_commande_client",
      "Numéro Offre Stillnetwork": "tof_code",
      Date: "tfa_date_creation",
      Demandeur: "con_prenom_nom",
      Statut: "tfa_custom_status",
      "Montants HT": "tfa_total_ht",
      Solde: "tfa_solde",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const formatMoney = (n) => {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (status) => {
      if (status == "Réglée") return "btn-icon-success";
      if (status == "Facture échue") return "btn-icon-danger";
      return "btn-icon-info";
    };

    const styleImgStatus = (status) => {
      if (status == "Réglée") return "media/icons/duotune/general/gen048.svg";
      if (status == "Facture échue")
        return "media/icons/duotune/general/gen050.svg";
      return "media/icons/duotune/general/gen049.svg";
    };

    const facturePDF = async (mFact) => {
      state.mFact = mFact;
      const resultAdr = await getAxios("/getFactureGed/" + mFact.tfa_seq);

      const records = resultAdr.records;
      const srcPDF = "data:application/pdf;base64," + records[0].ged_document;
      state.sourcePDF = srcPDF;
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Liste des factures", [
        "Home",
        "Board Stillnetwork",
      ]);

      let myList = await getAxios("/allFactures");
      state.initialMyList = myList.results;
      state.myList = myList.results;
      state.loaderEnabled = false;
      if (router.currentRoute.value.params.code) {
        const index = state.myList.findIndex(
          (object: any) =>
            object.tfa_code === router.currentRoute.value.params.code
        );
        facturePDF(state.myList[index]);
      }
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      facturePDF,
      tableFilters,
      ExcelFormat,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
